import React, { useEffect, useRef, useState } from "react";
// import { Dialog } from "@eds/vanilla";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import AlertWarnEDS from "../../../components/AlertWarn/AlertWarnEDS";
import Loading from "../../../components/Loading/Loading";
import ManagerListTable from "./ManagerListTable";
import {
    useAddLoneWorkersMutation,
    useUpdateLoneWorkersMutation,
    useDeleteLoneWorkersMutation,
} from "../../../redux/services/loneworkerApi";
import AddManagerPopup from "./AddManagerPopup"

let othersNextId = 0;

export default function ManagerList({ getManagerModalShow, setManagerModalShow }) {
    const [addLoneWorkers, { isLoading: addLoading }] =
        useAddLoneWorkersMutation();
    const [updateLoneWorkers, { isLoading: updateLoading }] =
        useUpdateLoneWorkersMutation();
    const [deleteLoneWorkers, { isLoading: deleteLoading }] =
        useDeleteLoneWorkersMutation();

    const [actionType, setActionType] = useState("Submit");
    const [selectedLoneworker, setSelectedLoneworker] = useState({
        email: "",
        employeeId: "",
    });

    const [selectedManager, setSelectedManager] = useState({
        email: "",
        phone: "",
    });
    const [getOthersData, setOthersData] = useState([
        { id: ++othersNextId, email: "", code: "", phone: "" },
    ]);
    const [getResponseStatus, setResponseStatus] = useState({
        status_code: "",
        message: "",
    });



    const submitHandler = async (event) => {
        let status_code = 0;
        const others = [...getOthersData];
        const body = {
            employeeId: selectedLoneworker.employeeId,
            lone_worker_email: selectedLoneworker.email,
            manager_email_address: selectedManager.email,
            manager_phone_number: selectedManager.phone,
            other_email_address: others.map((val) => val.email).toString(),
            other_phone_number: others
                .map((val) =>
                    val.code && val.phone ? val.code + "-" + val.phone : [""]
                )
                .toString(),
        };
        if (actionType === "Submit") {
            await addLoneWorkers(body)
                .unwrap()
                .then((data) => {
                    status_code = data.status_code;
                    setResponseStatus({
                        status_code: data.status_code,
                        message: data.message,
                    });
                    data.status_code === 200 && clearHandler();
                })
                .catch((error) => {
                    setResponseStatus({
                        status_code: 201,
                        message: "Internal server Error",
                    });
                });
        } else if (actionType === "Update") {
            await updateLoneWorkers(body)
                .unwrap()
                .then((data) => {
                    status_code = data.status_code;
                    setResponseStatus({
                        status_code: data.status_code,
                        message: data.message,
                    });
                    data.status_code === 200 && clearHandler();
                })
                .catch((error) => {
                    setResponseStatus({
                        status_code: 201,
                        message: "Internal server Error",
                    });
                });
        } else if (actionType === "Delete") {
            await deleteLoneWorkers({ lone_worker_email: body.lone_worker_email })
                .unwrap()
                .then((data) => {
                    status_code = data.status_code;
                    setResponseStatus({
                        status_code: data.status_code,
                        message: data.message,
                    });
                    data.status_code === 200 && clearHandler();
                })
                .catch(() => {
                    setResponseStatus({
                        status_code: 201,
                        message: "Internal server Error",
                    });
                });
        }
        closeAlert(status_code);
    };

    const closeAlert = (status_code) => {
        setTimeout(() => {
            if (status_code === 200) {
                setManagerModalShow(false);
            }
            setResponseStatus({ status_code: 0, message: "" });

        }, 3000);
        setActionType("Submit")
    };

    const updateWorkersList = (type, value) => {
        const othersList = value.other_email_address_list.map((val, ind) => {
            const [code = "", phone = ""] = value.other_phone_number_list[ind]?.split("-") || [];
            return { id: ++othersNextId, email: val, code, phone };
        });

        setOthersData([...othersList]);
        setSelectedLoneworker({
            email: value.lone_worker_email || "",
            employeeId: value.employeeId || "",
        });
        setSelectedManager({
            email: value.manager_email_address || "",
            phone: value.manager_phone_number || "",
        });
        type !== "Delete" && setManagerModalShow(true);
    };

    const clearHandler = () => {
        if (actionType !== "Update") {
            setSelectedLoneworker({
                email: "",
                employeeId: "",
            });
        }
        setSelectedManager({
            email: "",
            phone: "",
        });
        setOthersData([{ id: ++othersNextId, email: "", code: "", phone: "" }]);
    };

    return (
        <>
            <div className="body">
                <>
                    {getManagerModalShow && (
                        <AddManagerPopup
                            setManagerModalShow={setManagerModalShow}
                            actionType={actionType}
                            setActionType={setActionType}
                            selectedLoneworker={selectedLoneworker}
                            setSelectedLoneworker={setSelectedLoneworker}
                            selectedManager={selectedManager}
                            setSelectedManager={setSelectedManager}
                            getOthersData={getOthersData}
                            setOthersData={setOthersData}
                            getResponseStatus={getResponseStatus}
                            submitHandler={submitHandler}
                            addLoading={addLoading}
                            updateLoading={updateLoading}
                            clearHandler={clearHandler}
                        />
                    )}

                    <div className="tile">
                        <div className="header">
                            <div className="left">
                                <div className="title">Individual Notifications</div>
                            </div>
                        </div>
                        <div className="content">
                            {getResponseStatus.status_code && !getManagerModalShow ? (
                                <AlertEDS
                                    errMsg={getResponseStatus.message}
                                    status_code={getResponseStatus.status_code}
                                    close={false}
                                />
                            ) : null}
                            <ManagerListTable
                                updateWorkersList={updateWorkersList}
                                setActionType={setActionType}
                            />
                        </div>
                    </div>
                </>
            </div>
            {deleteLoading && (
                <div className="SiderBarLoader">
                    <Loading />
                </div>
            )}
            {actionType === "Delete" && (
                <AlertWarnEDS
                    id="loneworker-delete"
                    errTitle={"Please Confirm!"}
                    errMsg={
                        <>
                            Are you sure you want to delete Beacon Monitor <br />
                            {selectedLoneworker.email}?
                        </>
                    }
                    btnText={"Delete"}
                    handleDeleteConfirm={submitHandler}
                    setDelete={() => {
                        clearHandler();
                    }}
                />
            )}
        </>
    );
}
