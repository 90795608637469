import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { Dialog } from "@eds/vanilla";
import { useEffect, useState } from "react";
import { emailValidation, phoneValidation } from "../../../utils/helperMethod";
import { getLoneWorkerAddSenderList } from "../../../api/loneWorker.api";
import CountryCode from "../../LoneWorker/countryCode.json";
import { Select } from "@eds/vanilla";
import { useSelector } from "react-redux";
import _ from "lodash";

export default function AddPanicRecipientPopup({
    setLoneWorkerModalShow,
    getCountryRef,
    setCountryRef,
    getResponce,
    setResponce,
    getError,
    setError,
    getInput,
    setInput,
    setLoading,
    getLoneWorkerSenderListData
}) {

    const { headers } = useSelector((state) => state.envVariables);

    useEffect(() => {
        const dialogDOM = document.getElementById("AddPanicRecipientPopup");
        if (dialogDOM) {
            const dialog = new Dialog(dialogDOM);
            dialog.init();
            dialog.show();
            return () => {
                dialog.destroy();
            };
        }
    }, []);

    useEffect(() => {
        if (!getCountryRef) {
            const selects = document.querySelectorAll(".countryCode");
            if (selects) {
                Array.from(selects).forEach((selectDOM) => {
                    const select = new Select(selectDOM);
                    select.init();
                });
            }
        }
    }, [getCountryRef]);

    const setResponceHandler = (val) => {
        setResponce((prevState) => ({
            ...prevState,
            [Object.keys(val)[0]]: Object.values(val)[0],
            [Object.keys(val)[1]]: Object.values(val)[1],
        }));
    };

    const handleSubmit = async (e) => {
        let param;
        if (getError.phoneNo === "" && getInput.phoneNo !== "" && getInput.countryCode !== "") {
            param = {
                mail: getInput.emailID,
                number: getInput.countryCode.split("-")[0] + getInput.phoneNo,
                // usergroup: UserGroupId
            };
        } else {
            param = {
                mail: getInput.emailID,
                number: "",
                // usergroup: UserGroupId
            };
        }
        await getLoneWorkerAddSenderList(
            setLoading,
            param,
            setResponceHandler,
            closeAlert,
            setCountryRef,
            headers
        );
        getLoneWorkerSenderListData();
        setInput({ emailID: "", countryCode: "", phoneNo: "" });
        setError({ ...getError, emailID: "", phoneNo: "" });
        const allElements = document.querySelectorAll(".customInput");
        allElements.forEach((element) => {
            element.classList.remove("valid");
        });
    };

    const closeAlert = () => {
        setTimeout(() => {
            setResponce({ status_code: "", message: "" });
            setLoneWorkerModalShow(false);
        }, 2000);
    };

    const countryCode = (e) => {
        setInput({ ...getInput, ["countryCode"]: e });
    };

    const countryCodeEdit = (e) => {
        setInput({ ...getInput, [e.target.name]: e.target.value });
    };

    return (
        <div
            className="dialog fullscreen"
            data-type="fullscreen"
            id="AddPanicRecipientPopup"
        >
            <div className="content">
                <div className="top">
                    <div className="title">Add Recipient</div>
                    <div className="right">
                        <button
                            className="btn-icon"
                            data-close="true"
                            onClick={() => {
                                setLoneWorkerModalShow(false);
                            }}
                        >
                            <i className="icon icon-cross"></i>
                        </button>
                    </div>
                </div>
                <div className="body">
                    <div className="tile">
                        <div className="row">
                            <div className="column sm-12 md-5 lg-5 xl-5">
                                <label htmlFor="input_email" className="required">
                                    Enter Email ID
                                </label>
                                <input
                                    id="input_email"
                                    name="emailID"
                                    value={getInput.emailID}
                                    className="customInput validation"
                                    type="text"
                                    placeholder="Enter Email ID"
                                    onChange={(e) => {
                                        setInput({
                                            ...getInput,
                                            [e.target.name]: e.target.value,
                                        });
                                        if (e.target.value === "") {
                                            setError({
                                                ...getError,
                                                [e.target.name]: "This field is required",
                                            });
                                            e.target.classList.add("invalid");
                                            e.target.classList.remove("valid");
                                        } else if (emailValidation(e)) {
                                            setError({
                                                ...getError,
                                                [e.target.name]:
                                                    "Please enter a valid Email ID",
                                            });
                                            e.target.classList.add("invalid");
                                            e.target.classList.remove("valid");
                                        } else {
                                            setError({ ...getError, [e.target.name]: "" });
                                            e.target.classList.remove("invalid");
                                            e.target.classList.add("valid");
                                        }
                                    }}
                                />
                                <span className="validation-msg">
                                    {getError.emailID !== "" && getError.emailID}
                                </span>
                            </div>

                            <div className="column sm-12 md-5 lg-5 xl-5">
                                <label htmlFor="input_phoneno" className="">
                                    Enter Phone no.
                                </label>
                                <div className="telno">
                                    {!getCountryRef ? (
                                        <div className="select countryCode" data-type="input-single">
                                            <div className="clickable current-options">
                                                <input
                                                    type="text"
                                                    id="countryCD"
                                                    name="countryCode"
                                                    placeholder="Country Code"
                                                    onChange={(e) => countryCodeEdit(e)}
                                                />
                                            </div>
                                            <div className="options-list">
                                                {CountryCode.map((i, k) => {
                                                    return (
                                                        <div
                                                            className="item"
                                                            onClick={() =>
                                                                countryCode(i.CODE + "-" + i.NAME)
                                                            }
                                                            key={k}
                                                        >
                                                            {i.CODE + "-" + i.NAME}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        <input
                                            type="text"
                                            value={getInput.countryCode}
                                            readOnly
                                            className="dummayCountry"
                                            name="countryCode"
                                            placeholder="Country Code"
                                        />
                                    )}

                                    <div className="phoneNoCont">
                                        <input
                                            id="input_phoneno"
                                            disabled={
                                                getInput.countryCode === "" ? true : false
                                            }
                                            name="phoneNo"
                                            value={getInput.phoneNo}
                                            className="customInput validation"
                                            type="text"
                                            placeholder="Enter Phone no."
                                            onChange={(e) => {
                                                setInput({
                                                    ...getInput,
                                                    [e.target.name]: e.target.value,
                                                });
                                                if (e.target.value === "") {
                                                    setError({
                                                        ...getError,
                                                        [e.target.name]: "This field is required",
                                                    });
                                                    e.target.classList.add("invalid");
                                                    e.target.classList.remove("valid");
                                                } else if (phoneValidation(e)) {
                                                    setError({
                                                        ...getError,
                                                        [e.target.name]:
                                                            "Please enter a valid phone no.",
                                                    });
                                                    e.target.classList.add("invalid");
                                                    e.target.classList.remove("valid");
                                                } else {
                                                    setError({
                                                        ...getError,
                                                        [e.target.name]: "",
                                                    });
                                                    e.target.classList.remove("invalid");
                                                    e.target.classList.add("valid");
                                                }
                                            }}
                                        />

                                        <span className="validation-msg">
                                            {getError.phoneNo !== "" && getError.phoneNo}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column sm-12 md-2 lg-2 xl-2 customAlign">
                                <button
                                    className="btn"
                                    disabled={
                                        getError.emailID !== "" || getInput.emailID === ""
                                    }
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    Submit
                                </button>
                            </div>

                            {getResponce.message !== "" && (
                                <div className="column sm-12">
                                    <br />
                                    <AlertEDS
                                        errMsg={getResponce.message}
                                        status_code={getResponce.status_code}
                                        close={false}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}