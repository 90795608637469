import React, { useEffect, useState } from "react";
import { Breadcrumb } from "@eds/vanilla";
import { useLocation, useNavigate } from "react-router-dom";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import moment from "moment-timezone";
import _ from "lodash";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { useSelector } from "react-redux";
import {
    getLoneWorkerData,
    getLoneWorkerFilters,

} from "../../api/loneWorker.api";
import AllSessionTable from "./AllSessionTable";
import LoneWorkerMap from "./LoneWorkerMap";


export default function AllSession() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(
        new Date(
            moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
        )
    );
    const [endDate, setEndDate] = useState(
        new Date(
            moment()
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format("YYYY-MM-DD 23:59:59")
        )
    );
    const [loadingVal, setLoading] = useState({
        loneWorkerData: true,
        isMap: true,
    });
    const [getStatusOption, setStatusOption] = useState([]);
    // const [getMetricsStatus, setMetricsStatus] = useState([]);
    const [getMapData, setMapData] = useState([]);
    const [getLocations, setLocations] = useState([]);
    const [errMsg, setErrMsg] = useState({
        loneWorkerData: "",
        loneWorkerFilterData: "",
    });
    // const { getUserAccess } = useContext(UserContext);
    const [getLoneData, setLoneData] = useState([]);
    const [getLoneFilterData, setLoneFilterData] = useState({
        loading: false,
        data: {},
        message: "",
    });

    const [isAutoCall, setIsAutoCall] = useState(false);
    const { headers } = useSelector((state) => state.envVariables);

    let datepickerData = {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        getStatusOption,
        setStatusOption,
        getLocations,
        setLocations,
        getLoneFilterData,
    };

    useEffect(() => {
        // const title = cards.find((val) => "/beacon-monitoring" + val.path === pathname)?.title || "";
        const element = document.getElementById("config-breadcrumb-action");
        if (element) {
            const breadcrumb = new Breadcrumb(element);
            breadcrumb.init([
                {
                    title: "Beacon Monitoring",
                    action: () => {
                        navigate("/beacon-monitoring");
                    },
                },
                {
                    title: "All Session",
                },
            ]);
            return () => {
                breadcrumb.destroy();
            };
        }
    }, [pathname]);

    const callGetDeficiencies = (isLodder) => {
        let param = {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            status: getStatusOption,
            state: getLocations,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            acknowledge: ["N,Y"]
        };
        if (
            !_.isNull(startDate) &&
            !_.isNull(endDate) &&
            getStatusOption.length &&
            getLocations.length
        ) {
            getLoneWorkerData(
                param,
                setLoadingHandler,
                setErrMsgHandler,
                setLoneData,
                isLodder,
                headers
            );

        } else {
            setLoneData({});
            let error = "";
            if (!getStatusOption.length) {
                error = "Please select at least one Status";
            } else if (
                !getLocations.length &&
                !getLoneFilterData.data?.locations.length
            ) {
                error = "No data found under this date range";
            } else if (!getLocations.length) {
                error = "Please select at least one Circle";
            }
            setErrMsg((val) => ({
                ...val,
                loneWorkerData: error,
                loneWorkerMetrics: error,
            }));
            setLoading((val) => ({
                ...val,
                loneWorkerData: false,
                loneWorkerMetrics: false,
                isMap: false,
            }));
        }
    };

    const setLoadingHandler = (val) => {
        setLoading((prevState) => ({
            ...prevState,
            [Object.keys(val)[0]]: Object.values(val)[0],
            [Object.keys(val)[1]]: Object.values(val)[1],
        }));
    };

    const setErrMsgHandler = (val) => {
        setErrMsg((prevState) => ({
            ...prevState,
            [Object.keys(val)[0]]: Object.values(val)[0],
        }));
    };

    useEffect(() => {
        const param = {
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        const searchURL = `?start_date=${param?.start_date}&end_date=${param?.end_date}&timeZone=${param?.timeZone}`;
        getLoneWorkerFilters(searchURL, setLoneFilterData, headers);
    }, []);

    useEffect(() => {
        if (!_.isEmpty(getLoneFilterData.data)) {
            setStatusOption(getLoneFilterData.data?.status.map((val) => val.id));
            setLocations(getLoneFilterData.data?.locations || []);
            // setMetricsStatus(getLoneFilterData.data?.status.map((val) => val.id));
            setIsAutoCall(true);
        }
    }, [getLoneFilterData.data]);

    useEffect(() => {
        if (isAutoCall) {
            callGetDeficiencies(0);
            setIsAutoCall(false);
        }
    }, [isAutoCall]);

    // const handleSettings = () => {
    //     setLoading({
    //         ...loadingVal,
    //         loneWorkerList: true,
    //     });
    // };

    //   const handleManagerList = () => {
    //     setManagerModalShow(true);
    //   };

    //   const closeAlert = () => {
    //     setErrMsg({
    //       ...errMsg,
    //       loneWorkerUserList: "",
    //       loneWorkerManagerList: "",
    //     });
    //     setManagerModalShow(false);
    //   };

    const disableButtton =
        loadingVal.loneWorkerData ||
        _.isNull(startDate) ||
        _.isNull(endDate) ||
        !getStatusOption.length ||
        !getLocations.length;

    const AlertData = (alert) => {
        let data = alert === "Overrun-Session" ? "Major" : alert === "Active-Session" ? "Minor" : alert === "Panic-Alert" && "Critical";
        return [{ name: data, value: alert === "Completed-Session" ? "Successful" : alert === "Active-Session" ? "Current" : alert }];
    };

    const getCord = (node, data) => {
        Object.values(data["Status"]).filter((i, k) => {
            node.push({
                name: data["Fullname"][k] + "<br>(" +
                    parseFloat(data["Location"][k].split(",")[0]) +
                    ", " +
                    parseFloat(data["Location"][k].split(",")[1]) +
                    ")",
                icon: "icon-profile",
                x: parseFloat(data["Location"][k].split(",")[1]),
                y: parseFloat(data["Location"][k].split(",")[0]),
                status: data["Status"][k] === "Completed-Session" ? "Successful" : data["Status"][k] === "Active-Session" ? "Current" : data["Status"][k],
                alerts: AlertData(data["Status"][k]),
            });
        });
    }

    useEffect(() => {
        let node = [];
        if (!_.isEmpty(getLoneData)) {
            getCord(node, JSON.parse(getLoneData.loneworker_session_data));
            setMapData(node);
        }
    }, [getLoneData])

    return (
        <div className="row">
            <div>
                <div className="breadcrumb m-lg" id="config-breadcrumb-action"></div>
            </div>
            <div className="tile filterDiv">
                <CustomDatePickerForm
                    formName="lone-worker-all-session"
                    datepickerData={datepickerData}
                    filterData={getLoneFilterData.data}
                    callApply={() => {
                        setIsAutoCall(true);
                    }}
                    disableButtton={disableButtton}
                // filtersAccess={filtersAccess}
                />
            </div>
            {getLoneFilterData.loading ? (
                <Loading />
            ) : getLoneFilterData.message !== "Success" ? (
                <AlertEDS
                    errMsg={getLoneFilterData.message}
                    status_code={201}
                    close={false}
                // closeAlert={closeAlert}
                />
            ) : (
                <>
                    <div className="tile sm-12 md-12 lg-12 xl-12">
                        <div className="header">
                            <div className="left">
                                <div className="title">All Lone Worker Sessions</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="column">
                                {loadingVal?.loneWorkerData ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {errMsg.loneWorkerData !== "Success" &&
                                            errMsg.loneWorkerData !==
                                            "No data available in this date range" ? (
                                            <AlertEDS
                                                errMsg={errMsg.loneWorkerData}
                                                status_code={201}
                                                close={false}
                                            />
                                        ) : (
                                            <>
                                                {!_.isEmpty(getLoneData) && (
                                                    <AllSessionTable
                                                        loneworkerData={JSON.parse(
                                                            getLoneData.loneworker_session_data
                                                        )}
                                                        callGetDeficiencies={callGetDeficiencies}
                                                    // handleSettings={handleSettings}
                                                    // handleManagerList={handleManagerList}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="tile sm-12 md-12 lg-12 xl-12">
                        <div className="header">
                            <div className="left">
                                <div className="title">Real-time Location Tracker</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="column">
                                {loadingVal?.isMap ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {errMsg.loneWorkerData !== "Success" &&
                                            errMsg.loneWorkerData !==
                                            "No data available in this date range" ? (
                                            <AlertEDS
                                                errMsg={errMsg.loneWorkerData}
                                                status_code={201}
                                                close={false}
                                            />
                                        ) : (
                                            <>
                                                {!_.isEmpty(getMapData) && (
                                                    <>
                                                        <LoneWorkerMap
                                                            getMapData={getMapData}
                                                            loneworkerActiveData={getLoneData.usergroup_location}
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
