import { EnvironmentVariables } from "../api/BackendEndpoint.api";
export const googleMapsKey = "AIzaSyDRKDDO62Em9_CVS5DMXkl6p6mlAPNY7dg";

// // // PROD
// export const tenantName = "login.safeworker.ericsson.net";
// export const tenantURI = "https://login.safeworker.ericsson.net/";
// export const tenantID = "313644fd-193b-4a96-8563-e59d23907f95";
// export const domain_url = "https://api.dashboard.safeworker.ericsson.net";
// export const client_ID = "ccdf2ec2-09ca-4818-9e92-15ed9e29728e";
// // export const redirect_URI = "https://dashboard.safeworker.ericsson.net/";
// export const redirect_URI = "http://localhost:3000";
// export const appURL = "https://safeworker.ericsson.net";

// // // Dev
// export const tenantName = "login.dev.safeworker.ericsson.net";
// export const tenantURI = "https://login.dev.safeworker.ericsson.net/";
// export const tenantID = "1f767369-66de-4ece-ba9f-42b8458bb798";
// export const domain_url = "https://api.dashboard.dev.safeworker.ericsson.net";
// export const client_ID = "be31b76a-790c-451d-9ad2-81cf30fa68fc";
// // export const redirect_URI = "https://dashboard.dev.safeworker.ericsson.net"
// export const redirect_URI = "http://localhost:3000";
// export const appURL = "https://dev.safeworker.ericsson.net";

// // // Acc
// export const tenantURI = "https://login.acc.safeworker.ericsson.net/";
// export const tenantName = "login.acc.safeworker.ericsson.net";
// export const tenantID = "bf7ce1de-3f84-4bc7-acdd-6777560485f5";
// export const domain_url = "https://api.dashboard.acc.safeworker.ericsson.net";
// export const client_ID = "5898c05d-2dae-4258-b7e0-7d82b1c074e9";
// // export const redirect_URI = "https://dashboard.acc.safeworker.ericsson.net/";
// export const redirect_URI = "http://localhost:3000"
// export const appURL = "https://acc.safeworker.ericsson.net";

//Uncomment below code for Production or Other Deployment
// export const domain_url = process.env.REACT_APP_ENVIRONMENT_VARIABLE_DASHBOARD
export const supportEmail = "support@safeworker.ai";
export const ConfigurationValues = await EnvironmentVariables();
export const {
  client_ID,
  redirect_URI,
  tenantID,
  tenantName,
  tenantURI,
  domain_url,
  appURL,
} = ConfigurationValues;
export const URL = {
  Login: domain_url + "/login",
  Profile: domain_url + "/user_details",
  ChangePassword: domain_url + "/change_password",
  GET_Access: domain_url + "/get_access",
  GET_ROLE_MAPPING: domain_url + "/role_mapping",
  GET_USER_LAST_LOGIN_DATE_TIME: domain_url + "/graph_last_logged",
  ERICSION: {
    USER_STATS_URL: domain_url + "/erc_userstats",
    GROUP_STATS_URL: domain_url + "/erc_userstats_static",
    REPORT_STATS_URL: domain_url + "/erc_analytics",
    REPORTS_URL: domain_url + "/erc_ntp",
    REPORTS_UPDATE_STATUS_URL: domain_url + "/update_status",
    GET_REPORTS_DATA: domain_url + "/overalldata",
    GET_USER_MANAGEMENT_EXPIRY: domain_url + "/get_validationperiod",
    GET_FORM_SUBMISSION_DATA: domain_url + "/form_submission_stats",
    GET_CREW_SUBMISSION_COUNT: domain_url + "/form_submission_count",
    GET_TOTAL_TIME_COUNT: domain_url + "/total_time_stats",
    GET_CLIMBER_DATA: domain_url + "/get_climber_details_optimized",
    REPORTS_CNC_URL: domain_url + "/get_climber_details",
    AI_INSIGHTS_URL: domain_url + "/erc_aiinsights",
    RISK_MANAGEMENT_URL: domain_url + "/erc_riskmgmt",
    DISPATCH_URL: domain_url + "/erc_qc",
    INCIDENT_URL: domain_url + "/incident_report_data",
    USER_COUNT_URL: domain_url + "/get_user_count_details",
    SITE_WALK_COUNT_URL: domain_url + "/get_region_details",
    SITE_WALK_COUNT2_URL: domain_url + "/get_name_completion_site_inspection",
    DEFICIENCIES_URL: domain_url + "/get_deficiency_details",
    Rejection_Reasons_URL: domain_url + "/get_rejected_details",
    DEFICIENCIES_UPDATE_URL: domain_url + "/update_deficiency",
    GET_SCORE_DETAILS: domain_url + "/get_score_details",
    AI_CONVERSION_DETAILS: domain_url + "/ai_approved_conversion_count",
    SITE_WALK_ACTION_ITEMS: domain_url + "/get_action_region_details",
    SITE_WALK_ALL_ACTIONS_DETTAILS: domain_url + "/get_all_actions_details",
    GET_SAFETY_ACTIONS: domain_url + "/get_safety_actions",
    SITE_WALK_SAFE_UNSAFE_GROUPED_BY_REGION:
      domain_url + "/safe_unsafe_grouped_by_region",
    GET_APPROVAL_REJECTION_OPTION:
      domain_url + "/get_approval_rejection_options",
    GET_AUDIT_COUNT_DATA: domain_url + "/get_target_crew_details",
    UPDATE_FAVORITES_DATA: domain_url + "/get_favouriteslist",
    GET_ASP_SUPPLIER_DATA: domain_url + "/get_asp_supplier_details",
    GET_AUDITCOUNT_BY_TYPE: domain_url + "/get_audit_type_details",
    GET_ACTIONS_FILTERS: domain_url + "/get_action_filters",
    GET_LONE_WORKER_DATA: domain_url + "/loneworkerdata",
    GET_LONE_WORKER_FILTERS: domain_url + "/get_loneworker_filters",
    GET_LONE_WORKER_SENDER_LIST: domain_url + "/manage_loneworker_sender_list",
    GET_LONE_WORKER_METRICS: domain_url + "/get_loneworker_metrics",
    GET_LONE_WORKER_USER_LIST: domain_url + "/get_user_list",
    GET_LONE_WORKER_SETTINGS: domain_url + "/get_lone_worker_settings",
    ADD_LONE_WORKER_SETTINGS: domain_url + "/add_lone_worker_settings",
    EDIT_LONE_WORKER_SETTINGS: domain_url + "/edit_lone_worker_settings",
    GET_LONEWORKER_ACKNOWLEDGE: domain_url + "/loneworker_acknowledge",
    UPLOAD_SAFETY_DIALOGUE_TOPIC: domain_url + "/upload_safety_dialogue_topic",
    GET_SAFETY_DIALOGUE_TOPIC:
      domain_url + "/get_reports_by_safety_dialogue_topic",
    UPLOAD_EMPLOYEE_LIST: domain_url + "/upload_smart_factory_names_list",
    GET_REPORTS_FILTERS: domain_url + "/get_inspection_filters",
    GET_EHS_REPORTS_DATA: domain_url + "/overalldata_inspection",
    GET_EHS_TOTAL_TIME_COUNT: domain_url + "/total_time_stats_inspection",
    GET_ADMIN_VIEW_USER: domain_url + "/admin_view_user",
    GET_ADMIN_DELETE_USER: domain_url + "/admin_delete_user",
    UPDATE_USER_STATUS: domain_url + "/active_inactive_user",
    RESET_ADMIN_PASSWORD: domain_url + "/admin_reset_password",
    GET_AUDITORS_LIST: domain_url + "/getauditorlist",
    GET_SITEWALK_GOALS_BY_AUDITORS: domain_url + "/gettabledata",
    ADD_SITEWALK_GOALS: domain_url + "/sitewalkgoals",
    GET_ROLE_DETAILS: domain_url + "/get_role_details",
    GET_USER_METRIC: domain_url + "/get_user_metric",
    GET_MONTHLY_REGISTERD_USER_COUNT:
      domain_url + "/monthly_registered_user_count",
    GET_MONTHLY_ACTIVE_USER_COUNT: domain_url + "/monthly_active_user_count",
    GET_DATE_WISE_REGISTERED_AND_ACTIVE_USER:
      domain_url + "/date_wise_registered_and_active_user",
    SWITCH_USER_GROUP: domain_url + "/switch_usergroup",
    POLICY_STATUS_UPDATE: domain_url + "/privacy_policy_check",
    GET_VALID_DOMAIN: domain_url + "/allowed_domain",
    WEATHER_SETTINGS: domain_url + "/usergroup_weather_details",
    GET_COMPANY_AND_ROLES: domain_url + "/get_roles_company_details",
    UPDATE_USER_ROLES: domain_url + "/addnewuser_roles",
    GET_FORM_LIST: domain_url + "/get_form_list",
    EMAIL_DISTRIBUTION_DETAILS: domain_url + "/email_distribution_details",
    GET_MAIL_USER_LIST: domain_url + "/get_mail_user_list",
    GET_CERTIFICATE_EXPIRY: domain_url + "/get_certificate_expiry",
    GET_CERTIFICATE_NOT_EXPIRED: domain_url + "/get_certificate_not_expired",
    GET_CERTIFICATE_LIST: domain_url + "/certificate_list",
    GET_CERTIFICATE_USERS: domain_url + "/get_certificate_user",
    GET_AUDITS_BY_BUSINESS: domain_url + "/get_audit_count_by_business_unit",
    GET_AUDITS_BY_CUSTOMER: domain_url + "/get_audit_count_by_customer",
    FORM_REVIEW: domain_url + "/form_review_details",
    INCIDENT_MANAGEMENT: domain_url + "/incident_mail_details",
    GET_MONTHLY_LICENSE: domain_url + "/get_monthy_license",
    ADD_COMMENT_GROUPED_REGION: domain_url + "/add_comment_grouped_by_region",
    GET_SAFETY_METRICS: domain_url + "/safety-metrics",
    GET_AI_PERFORMANCE: domain_url + "/graph/safety-metrics",
    UPLOAD_MULTIPLE_IMG: domain_url + "/upload_multipleimg",
    GET_HAZARD_AI_DATA: domain_url + "/get_hazard_ai_data",
    GET_ALL_PPE_AI_DATA: domain_url + "/get_all_ppe_ai_data",
    POST_POST_HAZARD_IDENTIFICATION: domain_url + "/api/v1/hazard-identification",
    GET_HAZARD_IDENTIFICATION_LIST: domain_url + "/api/v1/hazard-identifications",

  },
  MC_NEAL_PRO: {
    USER_STATS_URL: domain_url + "/mcn_userstats",
    REPORT_STATS_URL: domain_url + "/mcn_analytics",
    AI_INSIGHTS_URL: domain_url + "/mcn_aiinsights",
    INCIDENT_URL: domain_url + "/incident_report_data",
    USER_COUNT_URL: domain_url + "/get_user_count_details",
    Rejection_Reasons_URL: domain_url + "/get_rejected_details",
    GET_SCORE_DETAILS: domain_url + "/get_score_details",
    AI_CONVERSION_DETAILS: domain_url + "/ai_approved_conversion_count",
    GET_APPROVAL_REJECTION_OPTION:
      domain_url + "/get_approval_rejection_options",
    GET_LONE_WORKER_DATA: domain_url + "/loneworkerdata",
  },
  CLIENT_USERGROUP: {
    USERGROUP_URL: domain_url + "/get_usergroups",
    FORMDETAILS_URL: domain_url + "/get_formdetails",
    STRUCTURE_TYPES_URL: domain_url + "/get_structuretypes",
    GET_LOCATIONS_FILTER: domain_url + "/get_locations_filter?usergroup=",
  },
  INCIDENT_MANAGEMENT: {
    CUSTOMER_DETAILS: domain_url + "/get_customer",
    CUSTOMER_REGION: domain_url + "/get_region",
    CUSTOMER_MARKET: domain_url + "/get_market",
    GENERATE_TICKET: domain_url + "/generate_ticket",
    GENERATE_TICKET_DETAILS: domain_url + "/get_ticket_details",
  },
};
export const BACKGROUND_COLOR = "#1f1f1f";
export const FONT_COLOR = "#fff";
export const BLACK_COLOR = "#000";
export const HOVER_LABEL_BGCOLOR = "#444";
