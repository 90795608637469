import React, { useState } from "react";
import ManagerList from "./ManagerList";
import BeaconPanicList from "./BeaconPanicList";


export default function BeaconConfiguration() {
    const [getManagerModalShow, setManagerModalShow] = useState(false);
    const [getLoneWorkerModalShow, setLoneWorkerModalShow] = useState(false);

    return (
        <div className="row BeaconConfiguration">
            <div className="tile sm-12 md-12 lg-12 xl-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Notification Management</div>
                    </div>
                    <div className="right">
                            <div className="action" onClick={() => setManagerModalShow(true)}>
                                <span className="tooltip pointer">
                                    <i className="icon icon-plus text-xl"></i>
                                    <span className="message left blue">Add New</span>
                                </span>
                            </div>                     
                    </div>
                </div>
                <div className="content">
                    <div className="column">
                        <ManagerList getManagerModalShow={getManagerModalShow} setManagerModalShow={setManagerModalShow} />
                    </div>
                </div>
            </div>

            <div className="tile sm-12 md-12 lg-12 xl-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Default/Fallback Notification Recipients</div>
                    </div>
                    <div className="right">
                            <div className="action" onClick={() => setLoneWorkerModalShow(true)}>
                                <span className="tooltip pointer">
                                    <i className="icon icon-plus text-xl"></i>
                                    <span className="message left blue">Add New</span>
                                </span>
                            </div>                     
                    </div>
                </div>
                <div className="content">
                    <div className="column">
                        <BeaconPanicList getLoneWorkerModalShow={getLoneWorkerModalShow} setLoneWorkerModalShow={setLoneWorkerModalShow} />
                    </div>
                </div>
            </div>
        </div>
    );
}
