import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ColorScale, HorizontalBarChartStacked } from "@eds/vanilla";
import CustomGraphActions from "../../../components/CustomGraphActions/CustomGraphActions";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import _ from "lodash";
import UnsafeResponsePieChart from "./UnsafeResponsePieChart";

export default function SafeResponsesUnsafeResponsesGraph({ safeUnsafeResponsesData, getAnalyticsGraphsToggle, getSiteWalkDataByAnalyticsToggle, loadingVal, errMsg }) {
  const features = useSelector((state) => state.userAccess.features);
  useEffect(() => {
    if (!_.isEmpty(safeUnsafeResponsesData) && !loadingVal.safe_unsafe_responses) {
      const data = {
        common: safeUnsafeResponsesData?.map((obj) => obj?.Category),
        series: [
          {
            name: "Safe Responses",
            values: safeUnsafeResponsesData?.map((obj) => obj?.SafeCount),
          },
          {
            name: "Unsafe Responses",
            values: safeUnsafeResponsesData?.map((obj) => obj?.UnsafeCount),
          },
        ],
      };
      const chartHeight = data?.common?.length * 40;
      const mycolorScale = new ColorScale({ reverse: true });
      const chart = new HorizontalBarChartStacked({
        element: document.getElementById("safeResponsesUnsafeResponsesGraph"),
        data: data,
        x: { unit: "Count" },
        height: chartHeight,
        colorScale: mycolorScale,
      });
      mycolorScale.init();
      chart.init();
      return () => {
        chart.destroy();
        let element = document.getElementById(
          "safeResponsesUnsafeResponsesGraph"
        );
        while (element?.firstChild) {
          element.removeChild(element.firstChild);
        }
      };
    }
  }, [safeUnsafeResponsesData, loadingVal.safe_unsafe_responses]);

  return (
    <>
      <li
        tabIndex={5}
        className={`tile mb-sm accordion-item ${getAnalyticsGraphsToggle.safe_unsafe_responses ? "opened" : ""
          }`}
      >
        <div
          className="title stickyTileHeader"
          onClick={() => {
            getSiteWalkDataByAnalyticsToggle("safe_unsafe_responses");
          }}
        >
          <div className="header p-sm">
            <div className="left">
              Safe Responses Vs Unsafe Responses
            </div>
          </div>
        </div>
        <div className="content">
          {loadingVal.safe_unsafe_responses ? (
            <Loading />
          ) : (
            <>
              {errMsg.safe_unsafe_responses !== "Successful" ? (
                <AlertEDS
                  errMsg={errMsg.safe_unsafe_responses}
                  status_code={201}
                  close={false}
                />
              ) : (
                <>
                  <CustomGraphActions
                    nodeId={"safeResponsesUnsafeResponsesGraph"}
                    fileName={"SafeResponses_UnsafeResponses"}
                    data={safeUnsafeResponsesData}
                  />
                  <div id="safeResponsesUnsafeResponsesGraph"></div>

                  {features.some(
                    (val) => val.name === "SAFE_UNSAFE_RESPONSE_GRAPH" && val.value
                  ) && (
                      <>
                        <div className="header p-sm">
                          <div className="left">Unsafe Responses</div>
                        </div>
                        <UnsafeResponsePieChart
                          UnsafeResponseData={safeUnsafeResponsesData}
                        />
                      </>
                    )}
                </>
              )}
            </>
          )}
        </div>
      </li>


    </>
  );
}
