import React, { useState, useEffect } from "react";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";

export default function ActiveSession({ sessionCount }) {
    const [getData, setData] = useState();
    const _data = () => {
        let obj = {}
        Object.values(sessionCount.status).forEach((i, k) => {
            obj[i] = sessionCount.count[k];
        });
        setData(obj);
    }

    useEffect(() => {
        if (!_.isEmpty(sessionCount)) {
            _data();
        }
    }, [sessionCount])
    return (
        <>
            {_.isEmpty(getData) ? (
                <Loading />
            ) :
                (
                    <div className="row Box">
                        <div className="sm-12 md-4 lg-2 xl-2">
                            <div className="totalCount rightBorder">
                                <div className="count ">{getData["Miss Check-In"] + getData["Active-Session"]}</div>
                                <div className="countTitle">Total Sessions</div>
                            </div>
                        </div>
                        <div className="sm-12 md-8 lg-2 xl-2">
                            <div className="totalCount">
                                <div className="count color-yellow">{getData["Miss Check-In"]}</div>
                                <div className="countTitle color-yellow"><i className="icon icon-severity-minor color-yellow"></i> Missed Check-In</div>
                            </div>
                        </div>
                        <div className="sm-12 md-8 lg-2 xl-2">
                            <div className="totalCount">
                                <div className="count color-blue">{getData["Active-Session"]}</div>
                                <div className="countTitle color-blue"><i className="icon icon-severity-indeterminate color-blue"></i> Current</div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}