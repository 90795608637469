import { URL } from "../utils/Constant";
import _ from "lodash";
export const getLoneWorkerData = async (
  param,
  setLoading,
  setErrMsg,
  setLoneData,
  isLodder,
  headers
) => {
  if (isLodder === 1) {
    setLoading({
      loneWorkerData: false,
      // isMap: true,
    });
  } else {
    setLoading({
      loneWorkerData: true,
      isMap: true,
    });
  }

  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }

  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_DATA, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setLoneData(data.data);
    setErrMsg({
      loneWorkerData: data.message,
    });
    setLoading({
      loneWorkerData: false,
      isMap: false,
    });
  }
};

export const getLoneWorkerFilters = async (
  searchURL,
  setLoneFilterData,
  headers
) => {
  setLoneFilterData((val) => ({ ...val, loading: true }));
  try {
    const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_FILTERS + searchURL, {
      method: "GET",
      headers: headers,
    });
    const data = await resp.json();
    setLoneFilterData(data?.data || {});
    setLoneFilterData({
      loading: false,
      data: data?.data || {},
      message: data?.message || "Internal Server Error",
    });
  } catch {
    setLoneFilterData({
      loading: false,
      data: {},
      message: "Internal Server Error",
    });
  }
};

// export const getLoneWorkerMetrics = async (
//   param,
//   getMetricsStatus,
//   setLoading,
//   setErrMsg,
//   setLoneMetricsData,
//   isLodder,
//   headers
// ) => {
//   if (isLodder === 1) {
//     setLoading({
//       loneWorkerMetrics: false,
//     });
//   } else {
//     setLoading({
//       loneWorkerMetrics: true,
//     });
//   }

//   param.status = getMetricsStatus;
//   let formData = new FormData();
//   for (let i in param) {
//     formData.append(i, param[i]);
//   }

//   const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_METRICS, {
//     method: "POST",
//     body: formData,
//     headers: headers,
//   });
//   if (resp.ok) {
//     const data = await resp.json();
//     setLoneMetricsData(data.data);
//     setErrMsg({
//       loneWorkerMetrics: data.message,
//     });
//     setLoading({
//       loneWorkerMetrics: false,
//     });
//   }
// };

export const getLoneWorkerSenderList = async (
  setLoading,
  setLoneList,
  setErrMsg,
  headers
) => {
  setLoading(true);
  // const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST + "?usergroupId=" + UserGroupId, { method: 'GET', headers: headers });
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "GET",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setLoneList(JSON.parse(data.data));
    setLoading(false);
    setErrMsg({
      loneWorkerList: data.message,
    });
  }
};

export const getLoneWorkerAddSenderList = async (
  setLoading,
  param,
  setResponce,
  closeAlert,
  setCountryRef,
  headers
) => {
  setLoading(true);
  setCountryRef(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setResponce({
      status_code: data.status_code,
      message: data.message,
    });
    setLoading(false);
    closeAlert();
    setCountryRef(false);
  }
};

export const getLoneWorkerRemoveSenderList = async (
  setLoading,
  param,
  setResponce,
  closeAlert,
  headers
) => {
  setLoading(true);
  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }
  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_SENDER_LIST, {
    method: "PUT",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setResponce({
      status_code: data.status_code,
      message: data.message,
    });
    setLoading(false);
    closeAlert();
  }
};

export const getLoneWorkerAcknowledge = async (setLoading, setErrMsg, sessionId, closeAlert, headers) => {
  setLoading(true);
  const resp = await fetch(URL.ERICSION.GET_LONEWORKER_ACKNOWLEDGE + '?sessionId=' + sessionId, {
    method: "GET",
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    if (resp.status === 200) {

      setErrMsg(data.message);
      setTimeout(() => { closeAlert() }, 8000);
    } else {
      setErrMsg(data.message);
      setTimeout(() => { closeAlert() }, 8000);
    }
  }
  setLoading(false);
};

export const getPanicOverrun = async (
  param,
  setLoading,
  setErrMsg,
  setPanicOverrunData,
  setSessionCount,
  isLodder,
  headers
) => {
  if (isLodder === 1) {
    setLoading({
      panicOverrun: false,
      // isMap: true,
    });
  } else {
    setLoading({
      panicOverrun: true,
    });
  }

  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }

  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_DATA, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setPanicOverrunData(data.data.loneworker_session_data);
    setSessionCount(data.data.loneworker_stats_data)
    setErrMsg({
      panicOverrun: data.message,
    });
    setLoading({
      panicOverrun: false,
    });
  }
};

export const   getActiveMissChecking = async (
  param,
  setLoading,
  setErrMsg,
  setActiveMissCheckingData,
  isLodder,
  headers
) => {
  if (isLodder === 1) {
    setLoading({
      activeMissChecking: false,
      // isMap: true,
    });
  } else {
    setLoading({
      activeMissChecking: true,
    });
  }

  let formData = new FormData();
  for (let i in param) {
    formData.append(i, param[i]);
  }

  const resp = await fetch(URL.ERICSION.GET_LONE_WORKER_DATA, {
    method: "POST",
    body: formData,
    headers: headers,
  });
  if (resp.ok) {
    const data = await resp.json();
    setActiveMissCheckingData(data.data.loneworker_session_data);
    setErrMsg({
      activeMissChecking: data.message,
    });
    setLoading({
      activeMissChecking: false,
    });
  }
};