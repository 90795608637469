import React, { useState, useEffect } from "react";
import _ from "lodash";
import Loading from "../../components/Loading/Loading";

export default function CompleteSession({ sessionCount }) {
    const [getData, setData] = useState();
    const _data = () => {
        let obj = {}
        Object.values(sessionCount.status).forEach((i, k) => {
            obj[i] = sessionCount.count[k];
        });
        setData(obj);
    }
    
    useEffect(() => {
        if (!_.isEmpty(sessionCount)) {
            _data();
        }
    }, [sessionCount]);

    return (
        <>
            {_.isEmpty(getData) ? (
                <Loading />
            ) :
                (
                    <div className="row Box">
                        <div className="sm-12 md-4 lg-2 xl-2">
                            <div className="totalCount rightBorder">
                                <div className="count ">{getData["Panic-Alert"] + getData["Overrun-Session"] + getData["Completed-Session"]}</div>
                                <div className="countTitle">Total Sessions</div>
                            </div>
                        </div>
                        <div className="sm-12 md-8 lg-2 xl-2">
                            <div className="totalCount">
                                <div className="count color-red">{getData["Panic-Alert"]}</div>
                                <div className="countTitle color-red"><i className="icon icon-severity-critical color-red"></i> Panic Alerts</div>
                            </div>
                        </div>
                        <div className="sm-12 md-8 lg-2 xl-2">
                            <div className="totalCount">
                                <div className="count color-orange">{getData["Overrun-Session"]}</div>
                                <div className="countTitle color-orange"><i className="icon icon-severity-major color-orange"></i> Overrun</div>
                            </div>
                        </div>

                        <div className="sm-12 md-8 lg-2 xl-2">
                            <div className="totalCount">
                                <div className="count color-green">{getData["Completed-Session"]}</div>
                                <div className="countTitle color-green"><i className="icon icon-severity-warning color-green"></i> Successful</div>
                            </div>
                        </div>

                    </div>
                )
            }
        </>
    )
}