import React, { useEffect, useRef } from "react";
import { Table } from "@eds/vanilla";
import Loading from "../../../components/Loading/Loading";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { useGetLoneworkerSettingsQuery } from "../../../redux/services/loneworkerApi";

export default function ManagerListTable({ updateWorkersList, setActionType }) {
  const tableRef = useRef(null);
  const {
    data: {
      data: { lone_worker_settings_data } = {},
      message,
      status_code,
    } = {},
    error,
    isLoading,
  } = useGetLoneworkerSettingsQuery();

  const getTableData = (settingsData) => {
    const products = [];
    if (settingsData?.length > 0) {
      let _getManagerSettings = JSON.parse(settingsData || "{}");
      for (let k in _getManagerSettings["manager_email_address"]) {
        products.push({
          employeeId: _getManagerSettings["employeeId"][k],
          lone_worker_email: _getManagerSettings["lone_worker_email"][k],
          manager_email_address:
            _getManagerSettings["manager_email_address"][k],
          manager_phone_number: _getManagerSettings["manager_phone_number"][k],
          other_email_address_list:
            _getManagerSettings["other_email_address_list"][k],
          other_phone_number_list:
            _getManagerSettings["other_phone_number_list"][k],
        });
      }
    }
    return products;
  };

  useEffect(() => {
    const tableData = getTableData(lone_worker_settings_data);

    if (!tableRef.current) {
      const tableDOM = document.getElementById("loneWorkerManagerListTable");
      if (tableDOM) {
        const table = new Table(tableDOM, {
          data: tableData,
          columns: [
            {
              key: "lone_worker_email",
              title: "Worker",
              sort: "none",
            },
            {
              key: "manager_email_address",
              title: "Manager Email ID",
              sort: "none",
            },
            {
              key: "manager_phone_number",
              title: "Manager Phone No.",
              sort: "none",
            },
            {
              key: "other_email_address_list",
              title: "Other Email Receipents",
              cellClass: "otherList",
            },
            {
              key: "other_phone_number_list",
              title: "Other SMS Receipents",
              cellClass: "otherList",
            },
          ],
          sortable: true,
          scroll: true,
          actions: true,
          onCreatedActionsCell: (td, data) => {
            td.innerHTML = `<button class="btn-icon edit mr-bs" data-index=${data.indexOriginal}><i class="icon icon-edit"></i></button> <button class="btn-icon delete" id="open-warning" data-index=${data.indexOriginal}><i class="icon icon-trashcan"></i></button>`;
            td.querySelector("button.edit").addEventListener("click", (evt) => {
              setActionType("Update");
              updateWorkersList("Update", data);
            });
            td.querySelector("button.delete").addEventListener(
              "click",
              (evt) => {
                setActionType("Delete");
                updateWorkersList("Delete", data);
              }
            );
          },
        });
        table.init();
        tableRef.current = table;
      }
    } else {
      tableRef.current.update(tableData);
    }
  }, [lone_worker_settings_data]);

  return (
    <>
      {isLoading && <Loading />}
      {status_code && status_code !== 200 && (
        <AlertEDS errMsg={message} status_code={201} close={false} />
      )}
      {error && (
        <AlertEDS
          errMsg={"Internal Server Error"}
          status_code={201}
          close={false}
        />
      )}
      {isLoading || error || (status_code && status_code !== 200) ? null : (
        <div>
          <div className="table-top">
            <div className="table-top-left">
              <div className="table-info"></div>
            </div>
            <div className="table-top-right">
              <div className="table-search">
                <input
                  type="text"
                  className="with-icon"
                  placeholder="Search for..."
                />
                <div className="suffix icon-inside">
                  <i className="icon icon-search"></i>
                </div>
              </div>
            </div>
          </div>
          <table
            id="loneWorkerManagerListTable"
            className="table split-dashed"
          ></table>
          <div className="table-bottom">
            <div className="pagination-group">
              <ul className="pagination"></ul>

              <div className="actions">
                <label className="left">Go to</label>
                <input type="number" min="1" />

                <label className="left">Show</label>
                <div className="select" data-type="single">
                  <button className="btn current-options">5</button>

                  <div className="options-list">
                    <div className="item active">5</div>
                    <div className="item">10</div>
                    <div className="item">15</div>
                    <div className="item">All</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
