import MultiSelectDropDown from "../CustomMultiSelectDropDown/MultiSelectDropDown";
import { useEffect, useState } from "react";
import _ from "lodash";
import EDSDatePicker from "../Datepicker/EDSDatePicker";
import { useSelector } from "react-redux";
//import EDSSingleSelect from "../EDSSingleSelect/EDSSingleSelect";
import moment from "moment";
import SingleSelectDropDown from "../CustomSingleSelect/CustomSingleSelect";
import SingleSelect from "../CustomSingleSelect/SingleSelect";

export default function CustomDatePickerForm({
  datepickerData,
  filterData = {},
  formName,
  callApply,
  disableButtton,
  filtersAccess,
}) {
  const { start_date, end_date } = useSelector(
    (state) => state.userAccess.filter_dates
  );
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [actionsUsers, setActionsUsers] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [formsDetails, setFormDetails] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [dashboardAccessDropdown, setDashboardAccessDropdown] = useState([]);
  const [requireReviewDropdown, setRequireReviewDropdown] = useState([]);
  const [certificateType, setCertificateType] = useState([]);
  const features = useSelector((state) => state.userAccess.features);
  const [businessUnitData, setBusinessUnitData] = useState([]);
  const locationName =
    features?.find((val) => val.name === "LOCATION").value || "Region";

  const [getAuditors, setAuditors] = useState([]);

  useEffect(() => {
    let statusList = [];
    if (formName === "reports") {
      statusList = [
        { formId: 1, form_name: "Submitted" },
        { formId: 2, form_name: "Approved" },
        { formId: 3, form_name: "Rejected" },
        { formId: 4, form_name: "AI Verified" },
        // { formId: 5, form_name: "In Progress" },
        { formId: 6, form_name: "AI Rejected" },
      ];
    } else if (formName === "userManagement") {
      statusList = [
        { formId: "Y", form_name: "Active" },
        { formId: "N", form_name: "InActive" },
      ];
    } else if (formName === "actions") {
      statusList = [
        { formId: "1", form_name: "Open" },
        { formId: "0", form_name: "Closed" },
      ];
    } else if (formName === "certification") {
      statusList = [
        { formId: 0, form_name: "Expired" },
        { formId: 1, form_name: "Expiring" },
        { formId: 2, form_name: "Valid" },
      ];
    } else {
      statusList = [];
    }
    setStatusDropdown([...statusList]);
    setDashboardAccessDropdown([
      { formId: "Y", form_name: "Yes" },
      { formId: "N", form_name: "No" },
    ]);
    setRequireReviewDropdown([
      { formId: true, form_name: "Yes" },
      { formId: false, form_name: "No" },
    ])
    setMarketData(
      filterData?.markets?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
    setCustomerData(
      filterData?.customers?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
    setSupplierData(
      filterData?.suppliers?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
    setRegionData(
      filterData?.locations?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
    setActionsUsers(
      filterData?.reporters?.map((val) => {
        const temp = Object.entries(val);
        return { formId: temp?.[0]?.[0], form_name: temp?.[0]?.[1]?.[0] };
      }) || []
    );

    setAuditors(
      filterData?.reporters?.map((val) => {
        const temp = Object.entries(val);
        return { formId: temp?.[0]?.[0], form_name: temp?.[0]?.[1] };
      }) || []
    );

    if (
      formName === "actions" ||
      formName === "ehsReports"
      // || formName === "site-walk"
    ) {
      setFormDetails(filterData?.form || []);
    } else if (formName === "site-walk") {
      setFormDetails(
        filterData?.form?.map((val) => ({
          value: val.formId,
          label: val.form_name,
        })) || []
      );
    } else {
      setFormDetails(filterData || []);
    }
    setStateData(
      filterData?.state?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
    setCertificateType(
      filterData?.certificateType?.map((val) => ({
        formId: val.certificateId,
        form_name: val.certificateType,
      })) || []
    );
    setBusinessUnitData(
      filterData?.business_unit?.map((val) => ({
        formId: val,
        form_name: val,
      })) || []
    );
  }, [filterData, formName]);

  function sitewalk() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Form:
          <SingleSelectDropDown
            // styles={customStyles}
            // classNamePrefix="singleSelect"
            formsDetails={formsDetails}
            datepickerData={datepickerData}
          // value={
          //   formsDetails.find(
          //     (val) => val.value === datepickerData.formIdData[0]
          //   ) || datepickerData.formIdData
          // }
          // isSearchable="true"
          // onChange={(value) => {
          //   datepickerData.setFormIdData([value.value]);
          // }}
          />
        </div>
        {!_.isEmpty(filtersAccess) && (filtersAccess).includes('region') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            {`${locationName}:`}
            <MultiSelectDropDown
              setStatusOption={datepickerData.setRegionDetails}
              formDetailsUG={regionData}
              defaultValues={datepickerData.getRegionDetails}
            />
          </div>
        )}

        {!_.isEmpty(filtersAccess) && (filtersAccess).includes('state') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            State:
            <MultiSelectDropDown
              formDetailsUG={stateData}
              setStatusOption={(statusOptions) =>
                datepickerData.setStateDetails(statusOptions)
              }
              // formName="Report-Status"
              defaultValues={datepickerData.getStateDetails}
            />
          </div>
        )}

        {!_.isEmpty(filtersAccess) && (filtersAccess).includes('reporter') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Auditor:
            <MultiSelectDropDown
              formDetailsUG={actionsUsers}
              setStatusOption={(statusOptions) =>
                datepickerData.setUsersOption(statusOptions)
              }
              defaultValues={datepickerData.getUsersOption}
              formName="Action-repoters"
              filterName="Reporter"
              dataType="input-multi"
            />
          </div>
        )}

        {(features?.some((val) => val.name === "MARKET" && val.value)) && !_.isEmpty(filtersAccess) && (filtersAccess).includes('markets') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Market:
            <MultiSelectDropDown
              formDetailsUG={marketData}
              setStatusOption={(statusOptions) =>
                datepickerData.setMarketDetails(statusOptions)
              }
              defaultValues={datepickerData.getMarketDetails}
              filterName="market"
              dataType="input-multi"
            />
          </div>
        )}

        {(features?.some((val) => val.name === "CUSTOMER" && val.value)) && !_.isEmpty(filtersAccess) && (filtersAccess).includes('customers') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Customer:
            <MultiSelectDropDown
              formDetailsUG={customerData}
              setStatusOption={(statusOptions) =>
                datepickerData.setCustomerDetails(statusOptions)
              }
              defaultValues={datepickerData.getCustomerDetails}
              filterName="customer"
              dataType="input-multi"
            />
          </div>
        )}

        {!_.isEmpty(filtersAccess) && (filtersAccess).includes('suppliers') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Supplier:
            <MultiSelectDropDown
              formDetailsUG={supplierData}
              setStatusOption={(statusOptions) =>
                datepickerData.setSupplierDetails(statusOptions)
              }
              defaultValues={datepickerData.getSupplierDetails}
              //formName="Report-Status"
              filterName="supplier"
              dataType="input-multi"
            />
          </div>
        )}

        {!_.isEmpty(filtersAccess) && (filtersAccess).includes('business_unit') && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Business Unit:
            <MultiSelectDropDown
              formDetailsUG={businessUnitData}
              setStatusOption={(statusOptions) =>
                datepickerData.setSelecetedBusinessUnit(statusOptions)
              }
              defaultValues={datepickerData.selectedBusinessUnit}
              //formName="Report-Status"
              filterName="BusinessUnit"
              dataType="input-multi"
            />
          </div>
        )}
      </>
    );
  }

  function ehsreport() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Form:
          <MultiSelectDropDown
            formDetailsUG={formsDetails}
            setStatusOption={(formId) => datepickerData.setFormIdData(formId)}
            defaultValues={datepickerData.formIdData}
            // filterName="form"
            dataType="input-multi"
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          {`${locationName}:`}
          <MultiSelectDropDown
            setStatusOption={datepickerData.setRegionDetails}
            formDetailsUG={regionData}
            defaultValues={datepickerData.getRegionDetails}
          />
        </div>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          State:
          <MultiSelectDropDown
            formDetailsUG={stateData}
            setStatusOption={(statusOptions) =>
              datepickerData.setStateDetails(statusOptions)
            }
            // formName="Report-Status"
            defaultValues={datepickerData.getStateDetails}
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Auditor:
          <MultiSelectDropDown
            formDetailsUG={actionsUsers}
            setStatusOption={(statusOptions) =>
              datepickerData.setUsersOption(statusOptions)
            }
            defaultValues={datepickerData.getUsersOption}
            formName="Action-repoters"
            filterName="Reporter"
            dataType="input-multi"
          />
        </div>
        {(features?.some((val) => val.name === "MARKET" && val.value)) && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Market:
            <MultiSelectDropDown
              formDetailsUG={marketData}
              setStatusOption={(statusOptions) =>
                datepickerData.setMarketDetails(statusOptions)
              }
              defaultValues={datepickerData.getMarketDetails}
              filterName="market"
              dataType="input-multi"
            />
          </div>
        )}
        {(features?.some((val) => val.name === "CUSTOMER" && val.value)) && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Customer:
            <MultiSelectDropDown
              formDetailsUG={customerData}
              setStatusOption={(statusOptions) =>
                datepickerData.setCustomerDetails(statusOptions)
              }
              defaultValues={datepickerData.getCustomerDetails}
              filterName="customer"
              dataType="input-multi"
            />
          </div>
        )}
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Supplier:
          <MultiSelectDropDown
            formDetailsUG={supplierData}
            setStatusOption={(statusOptions) =>
              datepickerData.setSupplierDetails(statusOptions)
            }
            defaultValues={datepickerData.getSupplierDetails}
            //formName="Report-Status"
            filterName="supplier"
            dataType="input-multi"
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Business Unit:
          <MultiSelectDropDown
            formDetailsUG={businessUnitData}
            setStatusOption={(statusOptions) =>
              datepickerData.setSelecetedBusinessUnit(statusOptions)
            }
            defaultValues={datepickerData.selectedBusinessUnit}
            //formName="Report-Status"
            filterName="BusinessUnit"
            dataType="input-multi"
          />
        </div>
      </>
    );
  }

  function action() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Form:
          <MultiSelectDropDown
            formDetailsUG={formsDetails}
            setStatusOption={(formId) => datepickerData.setFormIdData(formId)}
            defaultValues={datepickerData.formIdData}
            // filterName="form"
            dataType="input-multi"
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Status:
          <MultiSelectDropDown
            formDetailsUG={statusDropdown}
            setStatusOption={(statusOptions) =>
              datepickerData.setStatusOption(statusOptions)
            }
            defaultValues={datepickerData.getStatusOption}
            //formName="Report-Status"
            filterName="status"
            dataType="input-multi"
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          {`${locationName}:`}
          <MultiSelectDropDown
            setStatusOption={datepickerData.setRegionDetails}
            formDetailsUG={regionData}
            defaultValues={datepickerData.getRegionDetails}
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Auditor:
          <MultiSelectDropDown
            formDetailsUG={actionsUsers}
            setStatusOption={(statusOptions) =>
              datepickerData.setUsersOption(statusOptions)
            }
            defaultValues={datepickerData.getUsersOption}
            formName="Action-repoters"
            filterName="Reporter"
            dataType="input-multi"
          />
        </div>
        {(features?.some((val) => val.name === "MARKET" && val.value)) && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Market:
            <MultiSelectDropDown
              formDetailsUG={marketData}
              setStatusOption={(statusOptions) =>
                datepickerData.setMarketDetails(statusOptions)
              }
              defaultValues={datepickerData.getMarketDetails}
              filterName="market"
              dataType="input-multi"
            />
          </div>
        )}
        {(features?.some((val) => val.name === "CUSTOMER" && val.value)) && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            Customer:
            <MultiSelectDropDown
              formDetailsUG={customerData}
              setStatusOption={(statusOptions) =>
                datepickerData.setCustomerDetails(statusOptions)
              }
              defaultValues={datepickerData.getCustomerDetails}
              filterName="customer"
              dataType="input-multi"
            />
          </div>
        )}
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Supplier:
          <MultiSelectDropDown
            formDetailsUG={supplierData}
            setStatusOption={(statusOptions) =>
              datepickerData.setSupplierDetails(statusOptions)
            }
            defaultValues={datepickerData.getSupplierDetails}
            //formName="Report-Status"
            filterName="supplier"
            dataType="input-multi"
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Business Unit:
          <MultiSelectDropDown
            formDetailsUG={businessUnitData}
            setStatusOption={(statusOptions) =>
              datepickerData.setSelecetedBusinessUnit(statusOptions)
            }
            defaultValues={datepickerData.selectedBusinessUnit}
            //formName="Report-Status"
            filterName="BusinessUnit"
            dataType="input-multi"
          />
        </div>
      </>
    );
  }

  function loneworker() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Status:
          <MultiSelectDropDown
            setStatusOption={datepickerData.setStatusOption}
            formDetailsUG={
              filterData?.status?.map((val) => ({
                formId: val.id,
                form_name: val.name,
              })) || []
            }
            defaultValues={datepickerData.getStatusOption}
          />
        </div>
        {["Circle"].includes(locationName) && (
          <div className="column sm-6 md-4 lg-2 md-mt-bs">
            {`${locationName}:`}
            <MultiSelectDropDown
              setStatusOption={datepickerData.setLocations}
              formDetailsUG={regionData}
              defaultValues={datepickerData.getLocations}
            />
          </div>
        )}
      </>
    );
  }

  function reportStats() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Form:
          <MultiSelectDropDown
            formDetailsUG={formsDetails}
            setStatusOption={(formId) => datepickerData.setFormIdData(formId)}
            formName={"Reports-form"}
            defaultValues={datepickerData.formIdData}
          />
        </div>

        {formName === "reports" && (
          <>
            <div className="column sm-6 md-4 lg-2 md-mt-bs">
              Status:
              <MultiSelectDropDown
                formDetailsUG={statusDropdown}
                setStatusOption={(statusOptions) =>
                  datepickerData.setStatusOption(statusOptions)
                }
                formName="Report-Status"
                defaultValues={datepickerData.getStatusOption}
              />
            </div>

            <div className="column sm-6 md-4 lg-2 md-mt-bs">
              Requires Review:
              <MultiSelectDropDown
                formDetailsUG={requireReviewDropdown}
                setStatusOption={(formId) =>
                  datepickerData.setRequireReview(formId)
                }
                formName="Report-Status"
                defaultValues={datepickerData.getRequireReview}

              />
            </div>

          </>
        )}
        {/* <div className="column sm-12 lg-2 cstAplyfilDiv">
          <button
            className="btn primary"
            disabled={disableButtton}
            onClick={() => callApply(datepickerData.formIdData)}
          >
            Apply
          </button>
        </div> */}
      </>
    );
  }

  function userManagement() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Status:
          <MultiSelectDropDown
            formDetailsUG={statusDropdown}
            setStatusOption={(formId) => datepickerData.setFormIdData(formId)}
            formName={"userManagement"}
            defaultValues={datepickerData.formIdData}
          />
        </div>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Dashboard Access:
          <MultiSelectDropDown
            formDetailsUG={dashboardAccessDropdown}
            setStatusOption={(formId) =>
              datepickerData.setDashboardAccessId(formId)
            }
            formName={"userManagement"}
            defaultValues={datepickerData.dashboardAcessId}
          />
        </div>
      </>
    );
  }

  function siteWalkGoals() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Auditor:
          <MultiSelectDropDown
            formDetailsUG={getAuditors}
            setStatusOption={(statusOptions) =>
              datepickerData.setUsersOption(statusOptions)
            }
            defaultValues={datepickerData.getUsersOption}
            formName="Action-repoters"
            filterName="Reporter"
            dataType="input-multi"
          />
        </div>
      </>
    );
  }
  function certificateManagement() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Type:
          <MultiSelectDropDown
            formDetailsUG={certificateType}
            setStatusOption={datepickerData.setCertificateIds}
            formName={"certification"}
            defaultValues={datepickerData.certificateIds}
          />
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Status:
          <MultiSelectDropDown
            formDetailsUG={statusDropdown}
            setStatusOption={(statusOptions) =>
              datepickerData.setStatusOption(statusOptions)
            }
            formName="certification"
            defaultValues={datepickerData.getStatusOption}
          />
        </div>
      </>
    );
  }

  function hazardID() {
    return (
      <>
        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Customer:
          <SingleSelect
            formsDetails={filterData?.customerOption?.map(val => ({
              value: val,
              label: val,
            }))}
            setdatepickerData={datepickerData.setCustomer}
            getdatepickerData={datepickerData.getCustomer}
          ></SingleSelect>
        </div>

        <div className="column sm-6 md-4 lg-2 md-mt-bs">
          Site Type:
          <SingleSelect
            formsDetails={filterData?.SiteTypeOption?.map(val => ({
              value: val,
              label: val,
            }))}
            setdatepickerData={datepickerData.setSite_type}
            getdatepickerData={datepickerData.getSite_type}
          ></SingleSelect>
        </div>
      </>
    );
  }

  const getDateFilters = (form_name) => {
    const isHaveNameChange = ["user-stats", "userManagement"].includes(
      form_name
    );

    let startDate = "";
    let endDate = "";

    if (!["userManagement"].includes(form_name)) {
      startDate = new Date(
        moment(start_date).local().format("YYYY-MM-DD 00:00:00")
      );
      endDate = new Date(
        moment(end_date).local().format("YYYY-MM-DD 23:59:59")
      );
    } else {
      startDate = datepickerData.startDate;
      endDate = datepickerData.endDate;
    }

    return (
      <>
        {formName !== "certification" && formName !== "hazardID" && formName !== "lone-worker" ? (
          <>
            <div className="column sm-6 md-4 lg-2 md-mt-bs">
              {isHaveNameChange ? "Creation Start Date:" : "Start Date:"}
              <EDSDatePicker
                selectedDate={startDate}
                setDate={(date) => datepickerData.setStartDate(date)}
                filterName="filer-startDate"
              />
            </div>
            <div className="column sm-6 mddisableButtton-4 lg-2 md-mt-bs">
              {isHaveNameChange ? "Creation End Date:" : "End Date:"}
              <EDSDatePicker
                selectedDate={endDate}
                setDate={(date) => datepickerData.setEndDate(date)}
                filterName="filter-endDate"
              />
            </div>
          </>
        ) :
          formName === "lone-worker" && (
            <div className="column sm-6 md-4 lg-2 md-mt-bs">
              {isHaveNameChange ? "Creation Start Date:" : "Start Date:"}
              <EDSDatePicker
                selectedDate={startDate}
                setDate={(date) => datepickerData.setStartDate(date)}
                filterName="filer-startDate"
              />
            </div>
          )

        }
      </>
    );
  };

  const renderFilters = (form_name) => {
    switch (form_name) {
      case "report-stats":
        return reportStats();
      case "reports":
        return reportStats();
      case "site-walk":
        return sitewalk();
      case "lone-worker-all-session":
        return loneworker();
      case "actions":
        return action();
      case "ehsReports":
        return ehsreport();
      case "userManagement":
        return userManagement();
      case "siteWalkGoals":
        return siteWalkGoals();
      case "certification":
        return certificateManagement();
      case "hazardID":
        return hazardID();
      default:
        return null;
    }
  };

  return (
    <div className="row">
      {getDateFilters(formName)}
      {renderFilters(formName)}
      <div className="column sm-12 lg-2 cstAplyfilDiv md-mt-bs">
        <button
          className="btn primary"
          disabled={disableButtton}
          onClick={() => {
            callApply(0);
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}
